export const trans_FR = {
    calendarScreen: {
        helper: "Sélectionner votre jour de livraison désiré parmis les jours apparaissant en rose",
        actualDate: "Date de livraison programmée",
        initialDate: "Date de livraison initiale",
        error: {
            sameDate: "La date selectionner est identique à la date prévue.",
            responseError: "Service indisponible.",
            wsError: "Service est temporairement indisponible.",
            empty: "Veuillez selectionner la date de livraison souhaitée."
        },
        modal: {
          validationText: "Vous souhaitez programmer la date de livraison de votre prochain colis pour le :",
          confirmationText: "La date de livraison de votre prochain colis est maintenant programmmé pour le :"
        },
        mentions: {
            mention1: "Vous pouvez décaler votre colis si la date de livraison prévue est dans",
            mention1strong: "au moins 3 jours.",
            mention2: "Vous pouvez reporter ou avancer la date de livraison de votre colis dans",
            mention2strong: "la plage de dates indiquée en rose.",
            mention3: "Si vous souhaitez décaler la réception de votre colis au-delà de cette période, merci de contacter le service client au",
            mention3strong: "03 66 06 02 00.",
            mention4: "La date de livraison est estimée et pourrait varier."
        },
        btn: "Décaler ma livraison",
        title: "Je décale la date de livraison de :",
        cancel: "Annuler",
        modification: "Modifier",
        close: "Fermer",
        validation: "Valider"
    },
    url:{
        login: "connexion",
        register: "inscription",
        recovery: "mot-de-passe",
        home: "accueil",
        success: "mes-reussites",
        program: "mon-coaching",
        habit: "mes-habitudes",
        profile: "mes-reglages",
        activities: "mes-exercices",
        authentication: "authentication",
        advices: "mes-conseils",
        personalisation: "mes-plats",
        personalisationCja: "ma-personnalisation",
        resignation: "resiliation"
    },
    services:{
        email:{
            support: "support.technique@commejaime.fr",
            customer: "serviceclients@commejaime.fr"
        },
        phone:{
            customer: "03 66 06 02 00",
            commercial: "03 66 06 02 27"
        },
    },
    btn:{
        valid: "Valider",
        close: "Fermer",
        confirmation: "Confirmer",
        annulation: "Annuler",
        faq: "Je consulte la faq",
        send: "Envoyer ma demande",
        request: "Je rédige ma demande",
        rdv: "décaler mon rendez-vous",
    },
    label:{
        valid: "Valider"
    },
    dates: {
        to: "à",
        the: "Le",
        from: "Du",
        off: "au",
    },
    header:{
        title: "mon espace personnel",
        sos: "SOS CRAQUAGE",
        logout: 'Déconnexion'
    },
    footer:{
        help: "Besoin d'aide ? Contactez-nous !",
        help2:{
          text1: "Besoin",
          text2: "d'aide ?",
        },
        time: "Notre service client est aux petits soins pour vous du lundi au vendredi, de 09h à 19h",
        contact: {
            title: "service clients",
            phone: "Par téléphone",
            email: "Par email",
        },

        copyright: "© 2024 Comme J’aime - Tous droits réservés"
    },
    navBar: {
        hello: "Bonjour"
    },
    pages:{
        resignation:{
            title: "Résiliation",
            description: "Résilier un contrat"
        },
        personalisation:{
            title: "Mes plats",
            description: "Pour personnaliser les plats de votre prochain colis"
        },
        connection:{
            title: "Connexion",
            email: {
                title: "Votre adresse email",
                subTitle: "Veuillez renseigner votre adresse email dans le champs ci-dessous pour accéder à votre espace",
                placeholder: "Votre email"
            },
            password:{
                placeholder: "Mon mot de passe",
                title: "Mon mot de passe",
                sub1: "Veuillez renseigner le mot de passe associé à votre compte",
                mdpForgotten: "Mot de passe oublié",
                btnLabel: "Valider",
            },
            renewPassword:{
                placeholder:{
                    newPassword: "Votre nouveau mot de passe",
                    repeatPassword: "Confirmer le mot de passe"
                },
                title: "Votre nouveau mot de passe",
                textSubTitle1: "Pour plus de securité, veuillez modifier votre mot de passe.",
                textSubTitle2: "Veuillez à ce qu'il comporte au moins:",
            },
            forgetPassword: {
                title: "Mot de passe oublié",
                text: "Un email contenant un nouveau mot de passe va être envoyer à l'adresse suivante :",
                success: "Nouveau mot de passe envoyé !",
                successText: "Veuillez consulter votre boite mail pour découvrir votre nouveau mot de passe et vous connecter avec."
            },
            legal: {
                title: "Condition générales d'utilisation",
            },
        },
        legal:{
            title: "Nos conditions d'utilisation",
            sub: "La sécurité de vos données est au cœur de nos priorités. Pour finaliser votre inscription, veuillez cocher les cases ci-dessous :",
            read: "J’ai lu et j’accepte les",
            textCgu: "Conditions générales d’utilisation et la Charte de protection des données personnelles",
            textInfos: "J’accepte de recevoir des informations sur les Programmes COMME J’AIME grâce à mes données personnelles transmises sur l’espace client, l’application ou par la balance connectée",
            labelBtn: "Valider"
        },
        registration: {
            title: "Inscription"
        },
        recovery:{
            title: "Mot de passe"
        },
        home:{
            title: "Accueil",
            text1: "Bienvenue sur votre espace,",
            text2: "Veuillez sélectionner la fonctionnalité à laquelle vous souhaitez accéder.",
            app:{
                title: "Téléchargez l’appli COMME J’AIME",
                subTitle: "pour profiter de plus de fonctionnalités",
                intro: "En complément des séances avec votre coach, l’appli COMME J’AIME est LE soutien indispensable à votre projet de perte de poids.",
                arg1: "Suivez l'évolution de votre poids et de vos mensurations",
                arg2: "Admirez la transtormation de votre silhouette",
                arg3: "Pratiquez de l'activité physique adaptée",
                arg4: "Adoptez de bonnes habitudes pour longtemps",
            },
        },
        success:{
            slug: "success",
            title: "Mes progrès",
            menu: "Mes Progrès",
            description: "Pour suivre ma courbe de poids en toute simplicité.",
            graph: {
                year: "Année",
                month: "Mois",
                week: "Semaine",
                all: "Tout",
                measures: "Toutes les pesées",
                menu: "Mes graphiques",
                average: "Moyenne",
                unit: "kg",
                views: {
                    year: "Vue année",
                    month: "Vue mois",
                    week: "Vue semaine",
                },
                infos: {
                    title: {
                        text1: "Renseignez votre poids depuis",
                        text2: "notre application mobile !",
                    },
                    sub: {
                        text1: "Téléchargez notre appli COMME J'AIME pour renseigner votre poids et retrouver votre courbe de poids et vos évolutions ci-dessus !",
                    },
                },
                measure: {
                    empty: "Vous n'avez pas de mesures",
                    error: "Une erreur c'est produite, nous ne pouvons récupérer vos informations.",
                },
                evolution: {
                    my: "Ma",
                    first: "première",
                    last: "dernière",
                    fluctuation: "Fluctuation",
                    weight: "poids",
                    mine: "Mon",
                    actual: "actuel",
                    startProgram: "depuis le début du programme",
                    startYear: "sur l'année",
                    startMonth: "sur le mois",
                    startWeek: "sur la semaine",
                    scaleStartProgram: "pesée depuis le début du programme",
                    scaleYear: "pesée de l'année",
                    scaleMonth: "pesée du mois",
                    scaleWeek: "pesée de la semaine"
                }
            },
        },
        activities:{
            slug: "activity",
            title: "Mes exercices physiques",
            menu: "Mes exercices",
            description: "Pour pratiquer facilement de l’activité physique adaptée.",
            textTitle1: "Démarrez",
            textTitle2: "un programme d'activité physique !",
            textSubtitle1: "En période d'amincissement, il est",
            textSubtitle2: "primordial de pratiquer une activité",
            textSubtitle3: "physique afin de",
            textSubtitle4: "maintenir votre masse musculaire",
            textSubtitle5: "et",
            textSubtitle6: "conserver votre poids cible sur le long terme.",
            textApp1: "Téléchargez dès maintenant votre",
            textApp2: "application COMME J'AIME !",
            textExercise1: "+ de",
            textExercise2: "500 exercices",
            textExercise3: "d'activité physique adaptée",
            textProgression1: "Une progression croissante",
            textProgression2: "ajustée à votre profil",
            textTransformation1: "Une tranformation",
            textTransformation2: "de la silhouette assurée",
            textRewards1: "Des récompenses",
            textRewards2: "à débloquer pour rester motivé",
            textStart1 :"Et c'est parti pour",
            textStart2 :"maxinum 10 mn",
            textStart3 :"d'exercices physiques par jour !",
            textAvailable: "Bientôt disponible sur votre espace client !",
        },
        program:{
            slug: "program",
            title: "Mon coaching",
            menu: "Mon coaching",
            description: " Pour fixer des rendez-vous avec mon coach expert en diététique.",
            rdv: {
                title: {
                    text1: "Mon prochain",
                    text2: "rendez-vous coaching",
                },
                noRdv: "Vous n’avez pas de rendez-vous à venir.  Contactez votre Service Clients pour prendre RDV avec votre Coach Expert Diététique !",
                modal: {
                    change: {
                        title: "Modification de votre prochain rendez-vous coaching",
                        sub1: "Votre prochain rendez-vous coaching est prévu",
                        sub2: "Veuillez sélectionner la date et l’heure à laquelle vous souhaitez le décaler."
                    },
                    reservation: {
                         text1: "Vous souhaitez prendre rendez-vous le : ",
                    },
                    confirmation: {
                        text1: "Votre changement de rendez-vous a bien été pris en compte.",
                        text2: "Rendez-vous le"
                    },
                    error: {
                        text1: "Votre coach n'a pas de disponibilitées pour cette semaine",
                    },
                    empty: {
                        text1: "Votre coach n'a malheureusement plus de disponibilités",
                        text2: "Nous vous invitons à contacter votre service client",
                    },
                    actual: "Votre rendez-vous",
                }
            },
        },
        habit:{
            slug: "habit",
            title: "Mes habitudes",
            menu: "Mes habitudes",
            description: "Pour adopter de bons réflexes au quotidien.",
            intro:{
                text1: "Adoptez de nouvelles",
                text2: "Bonnes Habitudes",
                text3: "de façon simple et en seulement",
                text4: "quelques jours !",
            },
            goal: "Objectif: atteindre votre poids bonheur et le maintenir sur le long terme !",
            block1: {
                text1: "Nous avons créé pour vous",
                text2: "36 bonnes habitudes",
                text3: "pour",
                text4: "bien manger",
                text5: "bouger plus",
                text6: "et",
                text7: "mieux se connaitre",
            },
            advice: {
                exhausted: {
                    text1: "Vous êtes fatigué.e ?",
                    text2: "Vous ne perdez pas facilement de poids ?",
                    text3: "Adoptez la bonne habitude",
                    text4: "Je bois 1.5L d'eau par jour",
                },
                hunger: {
                    text1: "Vous avez l'habitude de compenser vos émotions par la nourriture ?",
                    text2: "Adoptez la bonne habitude",
                    text3: "Je tiens un carnet d'émotions",
                }
            },
            application: "Téléchargez maintenant notre application pour adopter vos nouvelles bonnes habitudes !"
        },
        profile:{
            slug: "profile",
            title: "Mes réglages",
            menu: "Mes réglages",
            email: "Votre demande a bien été transmise au Service Clients.",
            description: "Pour modifier mes données et mes informations.",
            resignation: {
                title: "Demande de",
                titleColor: "résiliation",
                link:  "Résilier mon abonnement"
            },
            data:{
                menu: "Mes données",
                header: {
                    title: {
                        text1: "Modification de certaines",
                        text2: "informations"
                    },
                    text: "Pour des raisons de sécurité, veuillez contacter votre service client pour modifier certaines informations (adresse email, numéro de téléphone, données de facturation).",
                    label: {
                        phone: "Par téléphone",
                        email: "Par email"
                    }
                },
                personal: {
                    title1: "Données",
                    title2: "personnelles",
                    textGender: "Vous êtes :",
                    label:{
                        male: "Monsieur",
                        female: "Madame",
                        record: "Enregistrer les modifications",
                        success: "Vos informations ont été modifiées",
                        birthdate: "Date de naissance :",
                    },
                },
                infos: {
                    title: {
                        text1: "Informations",
                        text2: "légales",
                    },
                    label: {
                        cgv: "Condition générales de ventes",
                    },
                    link: "Consulter"
                },
                general:{
                    title1: "Données",
                    title2: "générales",
                    refCustomer: "Votre référence client :",
                    phoneCustomer: "Votre numéro de téléphone :",
                    emailCustomer: "Votre adresse email :",
                    mdpCustomer: "Modifier votre mot de passe :",
                    mdp:{
                        form:{
                            placeholder:{
                                oldMdp: "Votre mot de passe actuel",
                                newMdp: "Votre nouveau mot de passe",
                                confMdp: "Confirmez votre mot de passe",
                            },
                        },
                        title: "Modification de votre mot de passe",
                        textActualMdp: "Pour pouvoir modifier votre mot de passe, veuillez renseigner votre mot de passe actuel :",
                        textNewMdp1: "Saississez votre nouveau mot de passe.",
                        textNewMdp2: "Veillez à ce qu'il comporte au moins :",
                        textRequireLength: "8 caractères",
                        textRequireMin: "1 minuscule",
                        textRequireNumber: "1 chiffre",
                        textRequireMaj: "1 majuscule",
                        textSamePassword: "Mots de passe identiques",
                        textOldPassword: "Mot de passe actuel",
                        mdpSuccess: "Votre mot de passe a été modifié",
                    },
                },
                billing: {
                    title: {
                        text1: "Données",
                        text2: "de facturation",
                    },
                    firstname: "Votre nom :",
                    lastname: "Votre prénom :",
                    address: "Adresse :",
                    code: "Code postal :",
                    city: "Ville :",
                    country: "Pays :",
                },
            },
            command: {
                multi: "Veuillez contacter le service client",
                menu: "Mes commandes",
                status: {
                    title: "Statut",
                    upcoming: "A venir",            //1
                    preparation: "En préparation",  //2
                    send: "Expédié",                //3
                    wait: "En attente",             //4
                    refused: "Refusé",              //5
                    returned: "Retourné",           //6
                    canceled: "Annulé",             //7
                    delivered: "Livré",             //8
                    started: "Démarré le",
                    ended: "Terminé le",
                },
                delivery: "Livraison",
                rank: "Rang",
                expedition: "Expédition",
                follow: "Suivi de colis",
                payment: "Prélèvement",
                price: "Montant",
                invoice: "Facture",
                next: {
                    text1: "Ma prochaine",
                    text2: "expédition de colis",
                },
                date: {
                    delivery: "Date de livraison :",
                    deliveryExpected: "Date de livraison prévue :",
                    expedition: "Date d'expedition :",
                    payment: "Date de prélèvement :"
                },
                equ: {
                    title: "Mes commandes Equilibroo"
                },
                cja: {
                    title: "Mes commandes Commejaime"
                },
                cpl: {
                    title: "Mes commandes de complements alimentaires"
                }
            },
            help: {
                menu: "Aide et assistance",
                title: {
                    text1: "Vous avez une",
                    text2: "question",
                },
                faq: "Consultez notre FAQ, il y a de grandes chances que vous trouviez la réponse à votre question !",
                noAnswer: {
                    text1: "Vous n'avez pas trouvé",
                    text2: "la réponse",
                    text3: "à votre question ?",
                },
                send: "Envoyez-nous un mail grâce à notre formulaire de contact.",
                form: "Commencez par sélectionner le thème de votre question :",
                themes: {
                    ap: "l'appli mobile",
                    scale: "la balance",
                    coaching: "le coaching",
                    cpl: "les compléments alimentaires",
                    ec: "l'espace client",
                    delivery: "les expéditions et les livraisons",
                    payment: "le paiment et la facturation",
                    refund: "les retours & remboursements",
                    meals: "les plats",
                    end: "la résiliation",
                    other: "un autre motif"
                },
                subject: {
                    title: "Vous nous contactez concernant",
                    sub: "Choisissez le motif de votre demande",
                    other: "Autres",
                    mobile: {
                        connection: "Connexion",
                        graphs: "Mes graphiques",
                        pictures: "Mes photos",
                        fitness: "Fitness",
                        habit: "Bonnes habitudes",
                        info: "Informations clients",
                    },
                    scale: {
                        hs: "Défaillance",
                        pair: "Erreur appairage",
                        data: "Données",
                        reception: "Réception",
                    },
                    coaching: {
                        rdv: "Déplacer / Annuler un RDV",
                        coach: "Changer de coach",
                        advice: "Obtenir des conseils diététiques",
                    },
                    cpl: {
                        request: "Demande d'informations",
                        reception: "Réception",
                        return: "Retours",
                    },
                    ec: {
                        connection: "Connexion",
                        graphs: "Graphiques",
                        rdv: "RDV coaching",
                    },
                    delivery: {
                        missing: "Commande non reçue",
                        report: "Décaler un colis",
                        damaged: "Colis endommagé",
                    },
                    payment: {
                        payment: "Régler une commande",
                        bill: "Etre informé sur ma situation comptable ou mes facturations"
                    },
                    refund: {
                        return: "Comment effectuer un retour",
                        info: "Avoir des informations sur un retour en cours",
                        refund: "Etre informé sur mon remboursement",
                    },
                    meals: {
                        infos: "Remonter des informations",
                    },
                    cancellation: {
                        end: "Résilier mon abonnement"
                    },
                },
                email: {
                    subject: "Vous nous contactez concernant",
                    details: "Afin de gagner du temps dans le traitement de votre dossier, veuillez nous indiquer avec précision tous les détails de votre demande.",
                    picture: "N’hésitez pas à joindre une photo pour compléter votre demande (3 photos maximum).",
                    placeholder: {
                        subject: "Je renseigne le motif de mon message",
                        message: "Je renseigne mon message ici",
                    },
                    label: "Joindre une photo",
                    success: "Votre demande a été transmise au service client",
                },
            },
        },
        advice: {
            slug: "advice",
            title: "Mes conseils",
            menu: "Mes conseils",
            description: "Pour m’aider pendant et après mon programme.",
        },
        sosBreaking:{
            slug: "sos",
            title:"SOS CRAQUAGE",
            description: " Pour être accompagné.e quand je suis sur le point de craquer.",
            btn:{
                prevQuestion: "Question précédente",
                nextQuestion: "Question suivante",
                breakDown: "J'ai craqué",
                sendSos: "Envoyer cet SOS",
                angryOk: "La faim m'est passée",
                desireOk: "L'envie m'est passée"
            },
            questions: {
                text1: "Vous êtes sur le point de craquer et de faire un écart ?",
                text2: "Nous sommes là pour vous accompagner !",
                text3: "Comprendre l'origine d'un «craquage» est incontournable pour y faire face.",
                text4: "Pour ce faire, nous allons vous poser 4 questions afin de vous aider à résister du mieux que l'on peut !",
            },
            results:{
                text1: "Afin de mettre toutes les chances de votre côté, voici les conseils que nous vous recommandons de suivre lorsque vous êtes sur le point de craquer dans ce genre de situation",
            }
        },
        eLearning: {
            title: "Mes quiz santé",
            sub: "Pour tester mes connaissances des guides COMME J'AIME"
        }
    },
    errors:{
        service: "Le service est momentanément indisponible",
        advices: "Les conseils sont indisponibles",
        personalisation: "La personnalisation est indisponible",
        login: {
            email:{
                notValid: "Veuillez renseigner une adresse email valide.",
                blank: "Veuillez renseigner une adresse email.",
                unknown: {
                    text1: "L'adresse",
                    text2: "n'est pas reconnue",
                    text3: "Vous êtes client COMME J'AIME",
                    text4: "et votre adresse e-mail n'est pas reconnue ?",
                    text5: "Contactez",
                    text6: "Vous n'êtes pas client COMME J'AIME ?",
                    text7: "et vous désirez plus d'informations ?",
                    text8: "Contactez-nous au",
                },
            },
            password:{
                blank: "Veuillez renseigner votre mot de passe.",
                incorrectMdp: "Le mot de passe renseigné est incorrect.",
                issue: "Ooops Un problème est survenu.",
                notAvailable: "Le service n'est pas disponible",
            },
            newPassword: {
                blank: "Veuillez renseigner dans chacun des champs votre nouveau mot de passe.",
                toShort: "Veuillez vérifier que votre nouveau mot de passe contient au moins 8 caractères."
            },
            repeatPassword: {
                blank: "Veuillez resaissir votre nouveau mot de passe.",
                notSame: "Veuillez vérifier la conformité entre les deux mots de passe renseignés.",
            },
            cguNoChecked: "Veuillez accepter les cgu"
        },
        profile: {
            data: {
                failed: "La mise à jour à échoué",
                failed2: "une erreur est survenue",
                birthday:{
                    blank: "Veuillez renseigner votre date de naissance"
                },
            },
            command: {
                error: "Nous ne pouvons afficher vos commandes"
            },
            help: {
                format: "Les formats acceptés pour les photos sont png ou jpeg",
                size: "La taille maximale des photos est de 5 Mo",
                noMessage: "Veuillez renseigner votre demande dans le champ dédié.",
                noSubject: "Veuillez renseigner le motif de votre demande dans le champ dédié",
            }
        }
    }
}
