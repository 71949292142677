import {cmdConstants} from "../_constants";

const initialState = {
    onInfosCmdProgress: false,
    infosCmdRequested: false,
    infosCmd: [],
    orders: null,
    calendarScreen: false,
    cmdSelectedId: null,
    dateSelected: null,
    activeKey: null,
    scrollTo: null,
    paymentIncidentShipping: null,
    refresh: false
}

export const cmdReducer = ( state = initialState, action) => {
    switch (action.type){
        case cmdConstants.CMD_GET_REQUEST:
            return {
                ...state,
                onInfosCmdProgress: true,
                infosCmdRequested: false
            }
        case cmdConstants.CMD_GET_SUCCESS:

            return {
                ...state,
                onInfosCmdProgress: false,
                infosCmdRequested: true,
                infosCmd: [ action.cmd]
            }
        case cmdConstants.CMD_GET_FAILURE:
            return {
                ...state,
                onInfosCmdProgress: false,
                infosCmdRequested: true
        }
        case cmdConstants.SET_ORDERS:
            return {
                ...state,
                orders: action.data
            }
        case cmdConstants.DELETE_ORDERS:
            return {
                ...state,
                orders: null
            }
        case cmdConstants.CMD_REFRESH:
            return {
                ...state,
                refresh: action.data
            }
        case cmdConstants.CMD_CALENDAR_SCREEN:
            return {
                ...state,
                calendarScreen: action.data
            }
        case cmdConstants.CMD_SELECTED_ID:
            return {
                ...state,
                cmdSelectedId: action.data
            }
        case cmdConstants.DATE_SELECTED:
            return {
                ...state,
                dateSelected: action.data
            }
        case cmdConstants.ACTIVE_KEY:
            return {
                ...state,
                activeKey: action.activeKey
            }
        case cmdConstants.SCROLL_TO:
            return {
                ...state,
                scrollTo: action.data
            }
        case cmdConstants.PAYMENT_INCIDENT_SHIPPING:
            return {
                ...state,
                paymentIncidentShipping: action.data
            }
        default:
            return state;
    }
}
